import { Component, PLATFORM_ID, Inject } from "@angular/core";
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from "@ngx-loading-bar/core";
import { TranslateService } from "@ngx-translate/core";
import { map, delay, withLatestFrom, take, tap, filter } from "rxjs/operators";
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1]),
    filter((progress) => progress === 100) 
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.translateService.setDefaultLang("pt-BR");
    this.translateService.use("pt-BR");
    this.onLoading();

    // this.loaders.subscribe((progress) => {
    //   if (progress === 100) {
    //     setTimeout(() => {
    //       this.onLoaded();
    //     }, 1000); // Espera mais 1 segundo antes de remover a classe
    //   }
    // });
    this.loaders.subscribe(() => {
      this.onLoaded();
    });

  }

  onLoading() {
    document.getElementsByTagName("body")[0].classList.add("offcanvas");

    const modalDialog = document.querySelector(".modal-dialog");
    if (modalDialog) {
      modalDialog.classList.add("offcanvas");
    }
  }

  onLoaded() {
    document.getElementsByTagName("body")[0].classList.remove("offcanvas");

    const modalDialog = document.querySelector(".modal-dialog");
    if (modalDialog) {
      modalDialog.classList.remove("offcanvas");
    }
  }
}
