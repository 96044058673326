import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class NoPageReloadInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Evitar que a página seja recarregada
    req = req.clone({
      headers: req.headers.set("X-Requested-With", "XMLHttpRequest"),
    });

    return next.handle(req);
  }
}
