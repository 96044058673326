import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "gestaoDeParceiros",
    loadChildren: () =>
      import("./../../components/gestaoDePessoas/gestao.module").then(
        (m) => m.GestaoModule
      ),
  },
  {
    path: "produtos",
    loadChildren: () =>
      import("./../../components/produtos/produto.module").then(
        (m) => m.ProdutoModule
      ),
  },
  {
    path: "servicos",
    loadChildren: () =>
      import("./../../components/servicos/servico.module").then(
        (m) => m.ServicoModule
      ),
  },
  {
    path: "agenda",
    loadChildren: () =>
      import("./../../components/agenda/agenda.module").then(
        (m) => m.AgendaModule
      ),
  },
  {
    path: "financeiro",
    loadChildren: () =>
      import("./../../components/financeiro/financeiro.module").then(
        (m) => m.FinanceiroModule
      ),
  },
  {
    path: "areaDoProfissional",
    loadChildren: () =>
      import(
        "./../../components/ariaDoProfissional/ariaDoProfissional.module"
      ).then((m) => m.AriaDoProfissionalModule),
  },
  {
    path: "configuracao",
    loadChildren: () =>
      import("./../../components/configuracoes/configuracoes.module").then(
        (m) => m.ConfiguracoesModule
      ),
  },
  {
    path: "relatorios",
    loadChildren: () =>
      import("./../../components/relatorios/relatorios.module").then(
        (m) => m.RelatoriosModule
      ),
  },
  {
    path: "notificacoes",
    loadChildren: () =>
      import("./../../components/notificacao/notificacao.module").then(
        (m) => m.NotificacaoModule
      ),
  },
];
