export class Empresa {
  public id_empresa: number;
  public id_usuario: number;
  public id_id_conta_a_pagar: number;
  public id_id_conta_a_receber: number;
  public no_empresa: string;
  public nu_cnpj_cpf: string;
  public tel_empresa: string;
  public end_empresa: string;
  public cid_empresa: string;
  public cep_empresa: string;
  public dt_criacao: string;
  public plano: string;
  public dt_expiracao: string;
  public dt_pagamento: string;
  public nu_end: number;
  public ds_bairro: string;
  public total_agendamentos_pendentes: string;
  public total_comissoes: string;
  public comandas_abertas: string;
  public total_contas_a_pagar_pendentes: string;
  public total_contas_a_receber_pendentes: string;
  public total_produtos_estoque_baixo: string;
  public vlr_total: string;
  public customerAsaasId: string;
  public subscriptionAsaasId: string;
  public ref_braip: string;

  public data: Empresa[];
}
