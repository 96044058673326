import { Component, OnInit } from "@angular/core";
import { Notificacao } from "src/app/components/notificacao/models/notificacao.model";
import { NotificacaoService } from "src/app/components/notificacao/services/notificacao.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  public openNotification: boolean = false;
  public notificacoes: any;

  public unreadCount: number = 0;
  public isLoading: boolean;

  constructor(private notificacaoService: NotificacaoService) {}

  ngOnInit() {
    this.load();
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  load() {
    this.isLoading = true;
    document.body.classList.add("offcanvas");
    this.notificacaoService.getAll().subscribe({
      next: (data) => {
        this.notificacoes = data || {};
      },
      error: () => {
        this.isLoading = false;
        document.body.classList.remove("offcanvas");
      },
      complete: () => {
        this.isLoading = false;
        document.body.classList.remove("offcanvas");
      },
    });
  }

  getTotalUnviewedNotifications(): number {
    if (this.notificacoes) {
      return this.notificacoes.filter(
        (notificacao) => !notificacao.in_visualizado
      ).length;
    }
    return 0; // Retorna 0 se não houver notificações
  }

  getNotificationClass(notificacao: Notificacao): string {
    return notificacao.in_visualizado
      ? "b-l-primary border-4"
      : "b-l-danger border-4";
  }
}
