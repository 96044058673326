import { Component, OnInit } from "@angular/core";
import { NavService, Menu } from "../../../../services/nav.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
  public menuItems: Menu[];
  public items: Menu[];

  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string = "";

  constructor(public navServices: NavService) {
    this.getMenuItems();
  }

  ngOnInit() {}

  getMenuItems() {
    this.navServices.getMenuItems().subscribe(menuItems => {
      this.items = menuItems;
      this.menuItems = menuItems;
    });
  }

  searchTerm() {
    if (!this.text) {
      this.menuItems = this.items;
      this.searchResultEmpty = false;
      this.searchResult = false;
      return;
    }

    this.addFix();
    let filteredItems = this.items.filter(item =>
      item.title.toLowerCase().includes(this.text.toLowerCase())
    );

    if (filteredItems.length === 0) {
      this.searchResultEmpty = true;
    } else {
      this.searchResultEmpty = false;
      this.menuItems = filteredItems;
    }
  }

  searchToggle() {
    this.navServices.search = false;
    document.getElementsByTagName("body")[0].classList.remove("offcanvas");
  }

  addFix() {
    this.searchResult = true;
    document.getElementsByTagName("body")[0].classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
    document.getElementsByTagName("body")[0].classList.remove("offcanvas");
  }
}
