<div *ngFor="let usuario of usuarios" class="media profile-media">
  <img
    class="b-r-10"
    [src]="
      usuario.ds_foto
        ? usuario.ds_foto
        : '../../../../../assets/images/dashboard/profile.png'
    "
    alt="Imagem do usuário"
    style="max-width: 45px; max-height: 50px"
  />
  <div class="media-body">
    <span>{{ usuario.no_usuario }}</span>
    <p class="mb-0 font-roboto">
      {{ usuario.cargo }}<i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>

<ul class="profile-dropdown onhover-show-div">
  <li>
    <a href="https://wa.me/5511932485026"
      ><app-feather-icons [icon]="'headphones'"></app-feather-icons
      ><span>Ajuda</span></a
    >
  </li>
  <li (click)="logoutFunc()">
    <a href="#"
      ><app-feather-icons [icon]="'log-in'"></app-feather-icons
      ><span>Sair</span></a
    >
  </li>
  <li (click)="open(content)">
    <app-feather-icons [icon]="'link'"></app-feather-icons
    ><span style="color: blue">Seus Links</span>
  </li>
</ul>
<ng-template #content let-modal modal-xl>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Links para Copiar</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <ul class="list-group">
          <li class="list-group-item text-wrap qr_content">
            <h5 style="color: black; font-size: 1em">Link de agendamento</h5>
            <a
              href="{{ linkAgendamento }}"
              target="_blank"
              style="word-wrap: break-word"
              >{{ linkAgendamento }}</a
            ><br />
            <canvas id="qrAgendamentoCanvas"></canvas>
            <div class="d-flex align-items-center justify-content-center mt-2">
              <button
                class="btn btn-primary"
                (click)="downloadQrCode('qrAgendamentoCanvas')"
              >
                Download QR
              </button>
            </div>
          </li>
          <li class="list-group-item qr_content">
            <h5 style="color: black; font-size: 1em">
              Link para página inicial
            </h5>
            <a
              href="{{ linkHome }}"
              target="_blank"
              style="word-wrap: break-word"
              >{{ linkHome }}</a
            ><br />
            <canvas id="qrHomeCanvas"></canvas>
            <div class="d-flex align-items-center justify-content-center mt-2">
              <button
                class="btn btn-primary"
                (click)="downloadQrCode('qrHomeCanvas')"
              >
                Download QR
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 col-md-6 col-12 mt-md-0 mt-4">
        <ul class="list-group">
          <li class="list-group-item text-wrap qr_content">
            <h5 style="color: black; font-size: 1em">
              Link para o painel do cliente
            </h5>
            <a
              href="{{ linkPainelCliente }}"
              target="_blank"
              style="word-wrap: break-word"
              >{{ linkPainelCliente }}</a
            ><br />
            <canvas id="qrPainelClienteCanvas"></canvas>
            <div class="d-flex align-items-center justify-content-center mt-2">
              <button
                class="btn btn-primary"
                (click)="downloadQrCode('qrPainelClienteCanvas')"
              >
                Download QR
              </button>
            </div>
          </li>
          <li class="list-group-item text-wrap qr_content">
            <h5 style="color: black; font-size: 1em">
              Link para o sistema de gestão
            </h5>
            <a
              href="https://adm.agendgest.com.br"
              target="_blank"
              style="word-wrap: break-word"
              >https://adm.agendgest.com.br</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Cancelar
    </button>
  </div>
</ng-template>
