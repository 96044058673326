import {
  trigger,
  animate,
  transition,
  style,
  query,
  group,
  animateChild,
  state,
} from "@angular/animations";

export const fadeInAnimation = trigger("fadeInAnimation", [
  // transition('* => *', [
  //     style({ position: 'relative' }),
  //     query(':enter, :leave', [
  //         style({
  //             position: 'absolute',
  //             top: 0,
  //             left: 0,
  //             width: '100%'
  //         })
  //     ],{ optional: true }),
  //     query(':enter', [style({ opacity: '0' })], { optional: true }),
  //     query(':leave', animateChild(), { optional: true }),
  //     group([
  //         query(':leave', [animate('.2s ease-in-out', style({ opacity: '0' }))], { optional: true }),
  //         query(':enter', [animate('.2s ease-in-out', style({ opacity: '1' }))], {
  //             delay: 300,
  //             optional: true,
  //         }),
  //     ]),
  //     query(':enter', animateChild(), { optional: true }),
  // ])
]);
