<div class="container-fluid">
  <div class="row">
    <div
      class="col-xl-7"
      [ngStyle]="{
        'background-image':
          'url(assets/images/imagens-projeto/imagem-calendario1.jpg)',
        'background-size': 'cover',
        'background-position': 'center center',
        display: 'block'
      }"
    >
      <img
        class="bg-img-cover bg-center d-none"
        src="assets/images/login/2.jpg"
        alt="looginpage"
      />
    </div>
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div>
            <img
              class="img-fluid for-light"
              style="width: 11em"
              src="../../../assets/images/imagens-projeto/logo-sem-fundo.png"
              alt="looginpage"
            />
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4 style="color: black">Faça login na conta</h4>
              <p>Digite seu e-mail e senha para fazer login</p>
              <div class="form-group">
                <label class="col-form-label">Email</label>
                <input
                  class="form-control"
                  type="email"
                  required=""
                  placeholder="exemplo@gmail.com"
                  formControlName="email"
                />
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  O e-mail é obrigatório
                </div>
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.email
                  "
                  class="text text-danger mt-1"
                >
                  E-mail inválido
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Senha</label>
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  formControlName="password"
                  required=""
                  placeholder="*********"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <span class="Hide"></span>
                </div>
                <div
                  *ngIf="
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  A senha é obrigatória
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" />
                </div>
                <a
                  style="display: none"
                  [routerLink]="'/authentication/forgot-password'"
                  class="link"
                  >Esqueceu sua senha?</a
                >
                <div class="text-end mt-3">
                  <button
                    class="btn btn-primary d-block w-100"
                    [disabled]="!loginForm.valid"
                    (click)="login()"
                    type="button"
                  >
                    <span>Entrar</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
