export class Notificacao {
  public id_notificacao_usuario: number;
  public id_notificacao: number;
  public id_usuario: number;
  public no_usuario: string;
  public dt_criacao: string;
  public in_visualizado: string;
  public ds_tipo_evento: string;
  public ds_mensagem: string;
}
