import { environment } from "./../../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Usuario } from "../models/usuario.model";
import { UsuarioApiResponse } from "../models/UsuarioApiResponse";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UsuarioService extends Usuario {
  private apiUrl = `${environment.domain.api}/usuario`;

  constructor(private http: HttpClient) {
    super();
  }

  public getAll(page: number): Observable<UsuarioApiResponse> {
    const apiUrlWithPage = `${this.apiUrl}?page=${page}`;
    return this.http.get<UsuarioApiResponse>(apiUrlWithPage);
  }

  public getUser(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${this.apiUrl}/getUser`);
  }

  public get(id_usuario: number): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.apiUrl}/${id_usuario}`);
  }

  public getSelect(): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.apiUrl}/getSelect`);
  }

  public update(id_usuario: number, data: any): Observable<Usuario[]> {
    const body = { id_usuario, ...data };
    return this.http.put<Usuario[]>(
      `${this.apiUrl}/update/${id_usuario}`,
      body
    );
  }

  public create(usuario: Usuario): Observable<Usuario> {
    return this.http.post<Usuario>(`${this.apiUrl}/create`, usuario);
  }
  public destroy(id_usuario: number): Observable<Usuario[]> {
    return this.http.delete<Usuario[]>(`${this.apiUrl}/delete/${id_usuario}`);
  }
}
