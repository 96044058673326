export const environment = {
  production: true,
  domain: {
    api: "https://api.agendgest.com.br/api", // Produção
    //api: "http://127.0.0.1:8000/api", // Desenvolvimento
  },
  firebaseConfig: {
    apiKey: "AIzaSyC6NCK8rQJQpcRWjiHO3YgmyccGTU83Zuc",
    authDomain: "agendgestfiles.firebaseapp.com",
    projectId: "agendgestfiles",
    storageBucket: "agendgestfiles.appspot.com",
    messagingSenderId: "304816072812",
    appId: "1:304816072812:web:f3c3d57d0da929b0121049",
    measurementId: "G-1G5DXZ23SV",
  },
};
