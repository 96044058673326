import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { Usuario } from "src/app/components/gestaoDePessoas/models/usuario.model";
import { UsuarioService } from "src/app/components/gestaoDePessoas/services/usuario.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import * as QRCode from "qrcode";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  @ViewChild("modalContent") modalContent: ElementRef;
  public usuarios: Usuario[];
  public linkAgendamento: string;
  public linkHome: string;
  public linkPainelCliente: string;
  private modalRef: NgbModalRef;

  constructor(
    public router: Router,
    public usuarioService: UsuarioService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.load();
  }

  logoutFunc() {
    sessionStorage.clear();
    this.router.navigateByUrl("auth/login");
  }

  load() {
    this.usuarioService.getUser().subscribe({
      next: (data) => {
        this.usuarios = data;
        const id_empresa = this.usuarios[0].id_empresa; // Ajuste conforme lógica de obtenção do id_empresa
        this.linkAgendamento = `https://agendgest.com.br/c/agendar?emp=${id_empresa}`;
        this.linkHome = `https://agendgest.com.br/c/home?emp=${id_empresa}`;
        this.linkPainelCliente = `https://agendgest.com.br/c/login?emp=${id_empresa}`;
      },
      complete: () => {},
    });
  }

  open(content): void {
    // Abre o modal
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
    });

    // Gera os QR codes ao abrir a modal
    this.generateQrCodes();
  }

  generateQrCodes(): void {
    // Gerar QR code para o link de agendamento
    this.generateQrCode(this.linkAgendamento, "qrAgendamentoCanvas");

    // Gerar QR code para o link de home
    this.generateQrCode(this.linkHome, "qrHomeCanvas");

    // Gerar QR code para o link do painel do cliente
    this.generateQrCode(this.linkPainelCliente, "qrPainelClienteCanvas");
  }

  private generateQrCode(link: string, canvasId: string): void {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    QRCode.toCanvas(canvas, link, { errorCorrectionLevel: "H" }, (error) => {
      if (error) {
        console.error(`Erro ao gerar QR code para ${link}`, error);
      }
    });
  }

  downloadQrCode(canvasId: string): void {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    const link = document.createElement("a");
    link.download = "qrcode.png";
    link.href = canvas.toDataURL();
    link.click();
  }
}
