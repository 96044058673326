import { environment } from "./../../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Notificacao } from "../models/notificacao.model";
import { NotificacaoApiResponse } from "../models/notificacaoApiResponse";

@Injectable({
  providedIn: "root",
})
export class NotificacaoService extends Notificacao {
  private apiUrl = `${environment.domain.api}/notificacaoUsuario`;

  private notificacoesSubject = new BehaviorSubject<Notificacao[]>([]);
  notificacoes$ = this.notificacoesSubject.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  public getAll(): Observable<NotificacaoApiResponse> {
    const apiUrlWithPage = `${this.apiUrl}`;
    return this.http.get<NotificacaoApiResponse>(apiUrlWithPage);
  }

  public search(page: number): Observable<NotificacaoApiResponse> {
    const apiUrlWithPage = `${this.apiUrl}/search?page=${page}`;
    return this.http.get<NotificacaoApiResponse>(apiUrlWithPage);
  }

  public update(
    id_notificacao_usuario: number,
    data: any
  ): Observable<Notificacao[]> {
    const body = { id_notificacao_usuario, ...data };
    return this.http.put<Notificacao[]>(
      `${this.apiUrl}/update/${id_notificacao_usuario}`,
      body
    );
  }
}
