export class Usuario {
  public id_usuario: number;
  public id_empresa: number;
  public no_usuario: string;
  public ds_email: string;
  public ds_senha: string;
  public ds_foto: string;
  public in_ativo: boolean;
  public in_atendimento: boolean;
  public dt_criacao: Date;
  public end_usuario: string;
  public cid_usuario: string;
  public nu_cpf: string;
  public pix_usuario: string;
  public tipo_pix: string;
  public tel_usuario: string;
  public comissao: number;
  public cargo: string;
  public salario: number;
}
