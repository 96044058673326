import { environment } from "../../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Empresa } from "../models/empresa.model";

@Injectable({
  providedIn: "root",
})
export class EmpresaService extends Empresa {
  private apiUrl = `${environment.domain.api}/empresa`;

  constructor(private http: HttpClient) {
    super();
  }

  public getAll(): Observable<Empresa> {
    const apiUrlWithPage = `${this.apiUrl}`;
    return this.http.get<Empresa>(apiUrlWithPage);
  }

  public getAllById(): Observable<Empresa> {
    const apiUrlWithPage = `${this.apiUrl}/getAllById`;
    return this.http.get<Empresa>(apiUrlWithPage);
  }

  public search(): Observable<Empresa> {
    const apiUrlWithPage = `${this.apiUrl}/search`;
    return this.http.get<Empresa>(apiUrlWithPage);
  }

  public searchAPagar(): Observable<Empresa> {
    const apiUrlWithPage = `${this.apiUrl}/searchAPagar`;
    return this.http.get<Empresa>(apiUrlWithPage);
  }

  public searchAReceber(): Observable<Empresa> {
    const apiUrlWithPage = `${this.apiUrl}/searchAReceber`;
    return this.http.get<Empresa>(apiUrlWithPage);
  }

  public searchRetorno(): Observable<Empresa> {
    let apiUrlWithParams = `${this.apiUrl}/searchRetorno`;
    return this.http.get<Empresa>(apiUrlWithParams);
  }

  public update(id_empresa: number, data: any): Observable<Empresa[]> {
    const body = { id_empresa, ...data };
    return this.http.put<Empresa[]>(
      `${this.apiUrl}/update/${id_empresa}`,
      body
    );
  }

  public create(Empresa: Empresa): Observable<Empresa> {
    return this.http.post<Empresa>(`${this.apiUrl}/create`, Empresa);
  }

  public destroy(id_empresa: number): Observable<Empresa[]> {
    return this.http.delete<Empresa[]>(`${this.apiUrl}/delete/${id_empresa}`);
  }
}
