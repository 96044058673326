import { Component, OnInit } from "@angular/core";
import { NavService, Menu } from "../../../../services/nav.service";

@Component({
  selector: "app-bookmark",
  templateUrl: "./bookmark.component.html",
  styleUrls: ["./bookmark.component.scss"],
})
export class BookmarkComponent implements OnInit {
  public menuItems: Menu[] = [];
  public items: Menu[] = [];
  public bookmarkItems: any[] = [];
  public text: string = "";

  public bookmarkFlip = false;
  public bookmark = false;

  public open = false;
  public searchResult = false;
  public searchResultEmpty = false;

  public bookmarksLoaded = false;

  constructor(public navServices: NavService) {
    this.getMenuItems();
  }

  ngOnInit(): void {
    this.loadBookmarks();
    this.navServices.items.subscribe((menuItems) => {
      this.items = menuItems;
      this.filterBookmarks();
      this.bookmarksLoaded = true;
    });
  }

  getMenuItems() {
    this.navServices.getMenuItems().subscribe((menuItems) => {
      this.items = menuItems;
      this.menuItems = menuItems;
    });
  }

  loadBookmarks() {
    const bookmarks = localStorage.getItem('bookmarks');
    if (bookmarks) {
      this.bookmarkItems = JSON.parse(bookmarks);
    }
  }

  saveBookmarks() {
    localStorage.setItem('bookmarks', JSON.stringify(this.bookmarkItems));
  }

  filterBookmarks() {
    this.items.filter((items) => {
      if (items.bookmark) {
        this.bookmarkItems.push(items);
      }
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.bookmark) {
          this.bookmarkItems.push(subItems);
        }
      });
      return;
    });
  }

  ToggleSearch() {
    this.open = !this.open;
    this.removeFix();
  }

  openBookMark() {
    this.bookmark = !this.bookmark;
  }

  flipBookMark() {
    this.bookmarkFlip = !this.bookmarkFlip;
  }

  searchTerm() {
    if (!this.text) {
      this.menuItems = this.items;
      this.searchResultEmpty = false;
      this.searchResult = false;
      return;
    }

    this.addFix();
    let filteredItems = this.items.filter((item) =>
      item.title.toLowerCase().includes(this.text.toLowerCase())
    );

    if (filteredItems.length === 0) {
      this.searchResultEmpty = true;
    } else {
      this.searchResultEmpty = false;
      this.menuItems = filteredItems;
    }
  }

  checkSearchResultEmpty(items: string) {
    if (!items.length) {
      this.searchResultEmpty = true;
    } else {
      this.searchResultEmpty = false;
    }
  }

  addFix() {
    this.searchResult = true;
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
  }

  addToBookmark(items: any) {
    const index = this.bookmarkItems.indexOf(items);
    if (index === -1 && !items.bookmark) {
      items.bookmark = true;
      this.bookmarkItems.push(items);
      this.saveBookmarks();
    } else {
      this.bookmarkItems.splice(index, 1);
      items.bookmark = false;
      this.saveBookmarks();
    }
  }
}
