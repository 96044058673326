import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  UntypedFormBuilder,
} from "@angular/forms";
import { AuthService } from "./services/authUser.service";
import { Router } from "@angular/router";
import { Menu, NavService } from "src/app/shared/services/nav.service";
const Swal = require("sweetalert2");
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private navService: NavService
  ) {
    this.loginForm = fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  showPassword() {
    this.show = !this.show;
  }

  // custom position
  alertaError() {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "error",
      title: "Email ou senha incorretos!",
    });
  }

  alertaUsuarioInativo() {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "warning",
      title:
        "Desculpe-nos, parece que sua conta não está ativa no momento. Por favor, entre em contato com o administrador para obter assistência. Obrigado!",
    });
  }

  login() {
    const { email, password } = this.loginForm.value;

    this.authService.login(email, password).subscribe(
      (response) => {
        if (response && response.usuario && response.usuario.in_ativo) {
          let user = {
            user: response.token,
          };

          sessionStorage.setItem("user", JSON.stringify(user));

          if (response && response.token) {
            sessionStorage.setItem("seuTokenKey", response.token);

            // Obter a lista de menu do usuário
            this.navService.getMenuItems().subscribe(
              (menuItems: Menu[]) => {
                sessionStorage.setItem("menuItems", JSON.stringify(menuItems));

                // Verifique se a lista de menu contém o caminho '/dashboard/default'
                const hasDashboardDefault = menuItems.some(
                  (item) => item.path === "/dashboard/default"
                );

                if (hasDashboardDefault) {
                  // Usuário está ativo e a lista de menu contém '/dashboard/default', redirecione para o dashboard
                  this.router.navigate(["/dashboard/default"]);
                } else {
                  // Usuário está ativo, mas a lista de menu não contém '/dashboard/default', redirecione para '/areaDoProfissional/minhaAgenda/listagem'
                  this.router.navigate([
                    "/areaDoProfissional/minhaAgenda/listagem",
                  ]);
                }
              },
              (error) => {
                console.error("Erro ao obter lista de menu:", error);
              }
            );
          }
        } else {
          this.alertaUsuarioInativo();
        }
      },
      (error) => {
        console.error("Login error:", error);
        this.alertaError();
      }
    );
  }
}
