<div class="notification-box" (click)="toggleNotificationMobile()">
  <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg>
  <span class="badge rounded-pill badge-secondary">{{
    getTotalUnviewedNotifications()
  }}</span>
</div>
<div
  class="onhover-show-div notification-dropdown"
  [class.show]="openNotification"
>
  <h6 class="f-18 mb-0 dropdown-title">Notificações</h6>
  <ul>
    <li
      *ngFor="let notificacao of notificacoes"
      [ngClass]="getNotificationClass(notificacao)"
    >
      <p>
        {{ notificacao.ds_tipo_evento }}
        <span
          [ngClass]="{
            'font-danger': !notificacao.in_visualizado,
            'font-primary': notificacao.in_visualizado
          }"
          >{{ notificacao.in_visualizado ? "Lida" : "Não Lida" }}</span
        >
      </p>
    </li>
    <li><a class="f-w-700" routerLink="/notificacoes">Ver Notificações</a></li>
  </ul>
</div>
