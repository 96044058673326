import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { EmpresaService } from "src/app/components/configuracoes/services/empresa.service";
import Swal from "sweetalert2";
interface MenuItem {
  id_menu_item: number;
  icon: string;
  path: string;
  title: string;
  type: string;
  in_ativo: boolean;
}

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  public empresa: any;
  public dataVencimentoPlano: string;
  public plano: string;

  constructor(private router: Router, private empresaService: EmpresaService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const menuItems: MenuItem[] = JSON.parse(
      sessionStorage.getItem("menuItems")
    );

    this.load();

    if (!user || !menuItems) {
      this.router.navigate(["/auth/login"]);
      // this.showAlert(
      //   "error",
      //   "Token Expirado",
      //   "O seu token de autenticação expirou. Por favor, faça o login novamente para continuar usando este serviço."
      // );
      return of(false);
    }

    const currentPath = state.url.split("?")[0];
    const hasAccess = menuItems.some(
      (item) => item.path === currentPath && item.in_ativo
    );

    if (!hasAccess) {
      this.showAlert(
        "error",
        "Acesso Negado",
        "Você não tem permissão para acessar essa página."
      );
      return of(false);
    }

    const menuItemConfiguracao = menuItems.find(
      (item) => item.path === "/configuracao"
    );

    if (this.isSistemaBloqueado() && !this.ehTelaDeFatura(state.url)) {
      if (menuItemConfiguracao) {
        if (this.plano === "Free") {
          this.mostrarModalFatura7Dia();
        } else {
          this.mostrarModalFatura();
        }
      } else {
        this.mostrarModalBloqueio();
      }
      return of(false);
    }

    return of(true);
  }

  load() {
    this.empresaService.getAllById().subscribe({
      next: (data) => {
        this.empresa = data[0];

        this.dataVencimentoPlano = this.empresa.dt_expiracao;
        this.plano = this.empresa.plano;
      },
      error: () => {},
      complete: () => {},
    });
  }

  private isSistemaBloqueado(): boolean {
    const dataVencimentoPlano = new Date(this.dataVencimentoPlano);
    const hoje = new Date();
    return hoje > dataVencimentoPlano;
  }

  private mostrarModalFatura(): void {
    Swal.fire({
      icon: "warning",
      title: "O seu plano expirou!",
      text: "Por favor, pague a fatura pendente para continuar utilizando o sistema.",
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonText: "Ver Fatura",
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/configuracao"]);
      }
    });
  }

  private mostrarModalFatura7Dia(): void {
    Swal.fire({
      icon: "warning",
      title: "Seu teste de 7 dias acabou!",
      html: `
      <p>Descubra todos os benefícios exclusivos que só nossos planos podem oferecer.</p>
      <p>Aproveite ao máximo nossos recursos avançados e transforme sua experiência.</p>
      <p>Estamos aqui para ajudá-lo a alcançar seus objetivos de forma eficaz e eficiente.</p>
      <p>Pronto para dar o próximo passo? Escolha o plano que melhor se adapta às suas necessidades e comece agora mesmo!</p>
    `,
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonText: "Ver Planos",
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/configuracao"]);
      }
    });
  }

  private mostrarModalBloqueio(): void {
    Swal.fire({
      icon: "warning",
      title: "Sistema Bloqueado!",
      text: "O acesso ao sistema está temporariamente indisponível devido à expiração do plano de acesso. Por favor, entre em contato com o responsável.",
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
    }).then((result) => {});
  }

  private ehTelaDeFatura(url: string): boolean {
    return url.includes("/configuracao");
  }
  showAlert(icon: "success" | "error", title: string, description?: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    Toast.fire({
      icon: icon,
      title: title,
      text: description,
    });
  }
}
